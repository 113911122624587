import React from "react"
import igIcon from "../../components/assets/img/icons/ig-icon.png"
import fbIcon from "../../components/assets/img/icons/fb-icon.png"

const FirstSection = () => {
    return (
        <section className="first">
            <div className="left">
                <h1 className="title yellow-font bold">
                    Em breve você poderá cadastrar seu usuário e senha.
                </h1>
                <h1 className="title white-font bold">
                    Aguarde nosso lançamento.
                </h1>
                <p className="description white-font">
                    Acesse nosso Instagram e fique por dentro das novidades que
                    estamos preparando para você.
                </p>
                <a
                    className="instagram-link"
                    href="https://www.instagram.com/gifthybrasil"
                    target="_blank"
                >
                    <img src={igIcon} alt="instagram gifthy" />
                    <h1 className="text white-font">gifthybrasil</h1>
                </a>
                <a
                    className="instagram-link"
                    href="https://www.fb.com/gifthybrasil"
                    target="_blank"
                >
                    <img src={fbIcon} alt="facebook gifthy" />
                    <h1 className="text white-font">gifthybrasil</h1>
                </a>
                <a href="/" className="back">
                    Voltar
                </a>
            </div>
        </section>
    )
}

export default FirstSection
